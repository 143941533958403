<template>
  <div class="container" v-if="ready">
    <h4 class="text-center my-4">Расписание</h4>
    <div class="text-center">
      <p>
        <strong>{{studentInfo?.barcode}} {{studentInfo?.last_name}} {{studentInfo?.first_name}}</strong>
      </p>
      <p>
        <strong>{{studentInfo?.educationProgram?.education_speciality_code}} {{studentInfo?.educationProgram?.education_speciality_name}} ({{studentInfo?.educationProgram?.admission_year}})</strong>
      </p>

    </div>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3 row">


      <div v-if="disciplines.length">


        <!-- disciplines section -->
        <div class="my-4">

          <div class="disciplines">


<!--            <div class="row fw-bold">-->
<!--              <div class="col-md-5">-->
<!--                <div class="row">-->
<!--                  <div class="col-12 col-md-2">№</div>-->
<!--                  <div class="col-12 col-md-10">Название дисциплины</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-1">Кредит</div>-->
<!--              <div class="col-md-4">Выбор</div>-->
<!--              <div class="col-md-2">Действие</div>-->
<!--            </div>-->

<!--            <div class="row border border-2 rounded-3 mt-2 mb-3 py-2"-->
<!--                 v-for="(discipline, disciplineIndex) in disciplines" :key="disciplineIndex">-->
<!--              <div class="col-md-5">-->
<!--                <div class="row">-->
<!--                  <div class="col-12 col-md-2">-->
<!--                  <span class="px-2 fs-4 text-center bg-light-blue rounded-3">-->
<!--                    {{ disciplineIndex + 1 }}-->
<!--                  </span>-->
<!--                  </div>-->
<!--                  <div class="col-12 col-md-10">-->
<!--                    <h5>-->
<!--                      <a class="text-blue text-decoration-none"-->
<!--                         @click="openDisciplineInfoModal(discipline.discipline_id)" style="cursor: pointer">-->
<!--                        {{ discipline.discipline_name }}-->
<!--                      </a>-->
<!--                    </h5>-->
<!--                    <div class="my-2">-->
<!--                      <a class="text-blue text-decoration-none"-->
<!--                         @click="openDisciplineGoalsModal(discipline.discipline_id)" style="cursor: pointer">-->
<!--                        Цели изучения дисциплины-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-1">-->
<!--                {{ discipline.credit }}-->
<!--              </div>-->

<!--              <div class="col-md-4">-->
<!--                <div v-if="discipline.seminar_lecture_array.length == 0">-->
<!--                  <p>Расписание не создано</p>-->
<!--                </div>-->
<!--                <div class="mt-4" v-for="(seminarLecture, seminarLectureIndex) in discipline?.seminar_lecture_array" :key="seminarLectureIndex">-->


<!--                  <div class="combined border" v-for="(combined, combinedIndex) in seminarLecture" :key="combinedIndex">-->
<!--                    <p>-->
<!--                      Выбор: <input class="form-check-input" type="radio" :name="disciplineIndex+'_'+seminarLectureIndex"-->
<!--                                    :checked="(discipline.new_lecture == null && discipline.new_seminar == null) ?-->
<!--                                    (combined.lecture == discipline.old_lecture && combined.seminar == discipline.old_seminar) :-->
<!--                                    (combined.lecture == discipline.new_lecture && combined.seminar == discipline.new_seminar)"-->
<!--                                    @change="changeChoose(discipline.discipline_id, combined.lecture, combined.seminar)">-->

<!--&lt;!&ndash;             :disabled="(combined.lecture == discipline.old_lecture && combined.seminar == discipline.old_seminar) ? btnEditDisabled[discipline.discipline_id] : false"         &ndash;&gt;-->
<!--                      &lt;!&ndash;                      :checked="combined[seminarLectureIndex][combinedIndex]"&ndash;&gt;-->
<!--&lt;!&ndash;                      :checked="isChecked(discipline.discipline_id, combined.lecture, combined.seminar)"&ndash;&gt;-->
<!--&lt;!&ndash;                      :disabled="isChecked(discipline.discipline_id, combined.lecture, combined.seminar)"&ndash;&gt;-->
<!--                    </p>-->
<!--                    <strong>● Лекция - {{combined?.lecture_pps_name[0]?.lastname}} {{combined?.lecture_pps_name[0]?.firstname}}</strong>-->
<!--                    <div v-if="combined.lecture_schedule.length == 0">-->
<!--                      <p>Пусто</p>-->
<!--                    </div>-->
<!--                    <div class="lecture" v-for="(lecture, lectureIndex) in combined.lecture_schedule" :key="lectureIndex">-->
<!--                      {{lecture.day_name}} - {{lecture.time}}-->
<!--                    </div>-->

<!--                    <strong>● Семинар - {{combined?.seminar_pps_name[0]?.lastname}} {{combined?.seminar_pps_name[0]?.firstname}}</strong>-->
<!--                    <div v-if="combined.seminar_schedule.length == 0">-->
<!--                      <p>Пусто</p>-->
<!--                    </div>-->
<!--                    <div class="seminar" v-for="(seminar, seminarIndex) in combined.seminar_schedule" :key="seminarIndex">-->
<!--                      {{seminar.day_name}} - {{seminar.time}}-->
<!--                    </div>-->

<!--                  </div>-->

<!--                </div>-->

<!--              </div>-->

<!--              <div class="col-md-2">-->
<!--                <div class="mt-4">-->
<!--                  <Button :class="discipline.discipline_id + discipline.semester" icon="pi pi-save"-->
<!--                          :loading="btnSaveDisabled[discipline.discipline_id]"-->
<!--                          label="Сохранить" @click="submit(discipline.discipline_id, discipline.semester)" />-->
<!--&lt;!&ndash;                  <Button :class="discipline.discipline_id + ' p-button-warning mt-2'" icon="pi pi-pencil"&ndash;&gt;-->
<!--&lt;!&ndash;                          label="Редактировать" @click="edit(discipline.discipline_id)" />&ndash;&gt;-->
<!--&lt;!&ndash;    :disabled="isSaved(discipline.discipline_id)"              &ndash;&gt;-->
<!--&lt;!&ndash;                  :disabled="!isTeacherSelected(discipline.discipline_id)"&ndash;&gt;-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->

            <Accordion>
              <AccordionTab v-for="(discipline, disciplineIndex) in disciplines" :key="disciplineIndex" :header="discipline.discipline_name">
                <p>
                   <a class="text-blue text-decoration-none"
                      @click="openDisciplineInfoModal(discipline.discipline_id)" style="cursor: pointer">
                     {{discipline.discipline_code}} {{ discipline.discipline_name }} ({{ discipline.credit }})
                   </a>
                  <br>
                  <a class="text-blue text-decoration-none"
                     @click="openDisciplineGoalsModal(discipline.discipline_id)" style="cursor: pointer">
                    Цели изучения дисциплины
                  </a>

                </p>

                <div v-if="discipline.seminar_lecture_array.length == 0">
<!--                  || (discipline.department_id == 9 && checkRole('student'))-->
                  <p>Расписание не создано</p>
                </div>

                <div v-else class="mt-4 row" v-for="(seminarLecture, seminarLectureIndex) in discipline?.seminar_lecture_array" :key="seminarLectureIndex">


                  <div class="col-md-3 combined border" v-for="(combined, combinedIndex) in seminarLecture" :key="combinedIndex">


                        <p>
                          Выбор: <input class="form-check-input" type="radio" :name="disciplineIndex+'_'+seminarLectureIndex"
                                        :checked="(discipline.new_lecture == null && discipline.new_seminar == null) ?
                                    (combined.lecture == discipline.old_lecture && combined.seminar == discipline.old_seminar) :
                                    (combined.lecture == discipline.new_lecture && combined.seminar == discipline.new_seminar)"
                                        @change="changeChoose(discipline.discipline_id, combined.lecture, combined.seminar)">
                        </p>
                        <strong>● Лекция - {{combined?.lecture_pps_name[0]?.lastname}} {{combined?.lecture_pps_name[0]?.firstname}}</strong>
                        <div v-if="combined.lecture_schedule.length == 0">
                          <p>Пусто</p>
                        </div>
                        <div class="lecture" v-for="(lecture, lectureIndex) in combined.lecture_schedule" :key="lectureIndex">
                          {{lecture.day_name}} - {{lecture.time}}
                        </div>

                        <strong>● Семинар - {{combined?.seminar_pps_name[0]?.lastname}} {{combined?.seminar_pps_name[0]?.firstname}}</strong>
                        <div v-if="combined.seminar_schedule.length == 0">
                          <p>Пусто</p>
                        </div>
                        <div class="seminar" v-for="(seminar, seminarIndex) in combined.seminar_schedule" :key="seminarIndex">
                          {{seminar.day_name}} - {{seminar.time}}
                        </div>





                  </div>



                </div>
                <div class="mt-2 text-center" v-if="discipline.seminar_lecture_array.length > 0">

                    <Button :class="discipline.discipline_id + discipline.semester + ' p-button-rounded'" icon="pi pi-save"
                            :loading="btnSaveDisabled[discipline.discipline_id]"
                            label="Сохранить" @click="submit(discipline.discipline_id, discipline.semester)" />
                </div>

              </AccordionTab>
            </Accordion>


          </div>


        </div>


        <!-- end disciplines section -->

      </div>

      <div v-else>
        <p>Дисциплин нет</p>
      </div>


      <!-- discipline info modal -->
      <Dialog :header="this.disciplines.find(i=>i.discipline_id == selected_discipline_id)?.discipline_name"
              v-model:visible="displayDisciplineInfo"
              :style="{width: '98%', maxWidth: '800px'}" :modal="true" :closable="false">
        <div v-for="(disciplineInfo, index) in disciplines.filter(i=>i.discipline_id == selected_discipline_id)"
             :key="index">
          <div>
            Язык дисциплины: {{ disciplineInfo?.discipline_language }}
          </div>
          <div class="mt-3">
            Код дисциплины: {{ disciplineInfo?.discipline_code }}
          </div>
          <div class="mt-4">
            Описание: {{ disciplineInfo?.discipline_description }}
          </div>
        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeDisciplineInfoModal"/>
        </template>
      </Dialog>
      <!-- end discipline info modal -->


      <!-- discipline goals modal -->
      <Dialog :header="this.disciplines.find(i=>i.discipline_id == selected_discipline_id)?.discipline_name"
              v-model:visible="displayDisciplineGoals"
              :style="{width: '98%', maxWidth: '800px'}" :modal="true" :closable="false">

        <div v-for="(disciplineInfo, index) in disciplines.filter(i=>i.discipline_id == selected_discipline_id)"
             :key="index">
          <div>
            <h5>Знания</h5>
            <div class="mt-1">
              {{ disciplineInfo?.knowledge }}
            </div>
          </div>
          <div class="mt-3">
            <h5>Навыки</h5>
            <div class="mt-1">
              {{ disciplineInfo?.skills }}
            </div>
          </div>
          <div class="mt-3">
            <h5>Умения</h5>
            <div class="mt-1">
              {{ disciplineInfo?.abilities }}
            </div>
          </div>
        </div>


        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeDisciplineGoalsModal"/>
        </template>
      </Dialog>
      <!-- end discipline goals modal -->
    </div>


  </div>
  <div class="container" v-else>
    <h2 class="text-center my-3">Выбор появиться после создания всех расписаний</h2>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from 'primevue/api';

export default {
  name: 'StudentSchedule',
  data() {
    return {
      ready: true,

      loading: true,
      btnSaveDisabled: {},
      btnEditDisabled: {},
      disciplines: [],
      urlStudentId: this.$route.query.student_id || null,
      studentInfo: {},


      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

        'discipline_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },


      selected_discipline_id: null,
      displayDisciplineGoals: false,
      displayDisciplineInfo: false,

      selected_lecture: null,
      selected_seminar: null,


      //Дисциплины у которых нет лекции (Физическая культура, ИКТ и дисциплины магистратуры)
      exception_disciplines: ['1516','2197','2200','1696','4491','4502','1684','4397','4398','1653','4227','2196','4039']


    }
  },
  computed: {
    ...mapState('studentSchedule', ['studentSchedule_form']),
  },
  methods: {
    ...mapActions('managerSchedule', ['GET_DAYS', 'GET_SCHEDULE_INTERVAL_TIMES']),
    ...mapActions('studentSchedule', ['GET_DISCIPLINES', 'POST_STUDENT_SCHEDULE_CHOOSE', 'DELETE_STUDENT_SCHEDULE_CHOOSE', 'PUT_STUDENT_SCHEDULE_CHOOSE', 'GET_STUDENT_INFO']),
    // checkRole(i) {
    //   const roles = JSON.parse(getCookie('ROLES')) || []
    //   if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    //   }
    //   return roles.includes(i)
    // },
    edit(discipline_id) {
      this.btnEditDisabled[discipline_id] = true
    },

    isChecked(discipline_id, lecture, seminar) {
      let discipline = this.disciplines.find(i => i.discipline_id == discipline_id)
      let seminar_lecture_array = discipline.seminar_lecture_array.find(i=>i.lecture == lecture && i.seminar == seminar)
      if (seminar_lecture_array) {
        return 1
      }
      else {
        return 0
      }
    },
    isSaved(discipline_id) {
      let discipline = this.disciplines.find(i => i.discipline_id == discipline_id)
      if (discipline.old_lecture != null && discipline.old_seminar != null) {
        return 1
      } else {
        return 0
      }
    },

    changeChoose(discipline_id, lecture, seminar) {
      console.log(discipline_id, 'discipline_id')
      console.log(lecture, 'lecture')
      console.log(seminar, 'seminar')
      let discipline = this.disciplines.find(i => i.discipline_id == discipline_id)
      discipline.new_lecture = lecture
      discipline.new_seminar = seminar

      console.log(discipline, 'changeChoose discipline')

    },


    isTeacherSelected(discipline_id) {
      let discipline = this.disciplines.find(i => i.discipline_id == discipline_id)

      if (discipline.new_lecture_pps_teaching_load_id == null || discipline.new_seminar_pps_teaching_load_id == null) {
        return false
      } else {
        return true
      }
    },
    goToTeacher(userId) {
      sessionStorage.setItem('USER', userId)
      let route = this.$router.resolve({path: '/teacher-info'});
      window.open(route.href, '_blank');
    },

    openDisciplineInfoModal(discipline_id) {
      this.selected_discipline_id = discipline_id
      console.log(this.selected_discipline_id, 'selected_discipline_id')
      this.displayDisciplineInfo = true
    },
    closeDisciplineInfoModal() {
      this.displayDisciplineInfo = false
    },

    openDisciplineGoalsModal(discipline_id) {
      this.selected_discipline_id = discipline_id
      console.log(this.selected_discipline_id, 'selected_discipline_id')
      this.displayDisciplineGoals = true
    },
    closeDisciplineGoalsModal() {
      this.displayDisciplineGoals = false
    },


    async submit(discipline_id) {
      this.btnSaveDisabled[discipline_id] = true
      console.log(discipline_id, 'discipline_id')

      let form = []
      let updateForm = []
      let discipline = this.disciplines.find(i=>i.discipline_id == discipline_id)
      let seminar_lecture_array = discipline.seminar_lecture_array
      console.log(seminar_lecture_array, 'seminar_lecture_array')
      let lecture_schedule = []
      let update_lecture_schedule = []
      let old_lecture_schedule = []
      let seminar_schedule = []
      let update_seminar_schedule = []
      let old_seminar_schedule = []


      if (discipline.old_lecture == null && discipline.old_seminar == null) {
        for (let i = 0; i<seminar_lecture_array.length; i++) {
          for (let j = 0; j<seminar_lecture_array[i].length; j++) {

            if (seminar_lecture_array[i][j].lecture == discipline.new_lecture && seminar_lecture_array[i][j].seminar == discipline.new_seminar) {
              lecture_schedule = seminar_lecture_array[i][j].lecture_schedule
              seminar_schedule = seminar_lecture_array[i][j].seminar_schedule
            }
          }
        }

        console.log(lecture_schedule, 'submit lecture_schedule')
        console.log(seminar_schedule, 'submit seminar_schedule')

        for (let k=0; k<lecture_schedule.length; k++) {
          form.push({
            schedule_id: lecture_schedule[k].schedule_id,
            sp_academic_year_id: lecture_schedule[k].sp_academic_year_id,
            student_id: this.urlStudentId
          })
        }

        for (let m=0; m<seminar_schedule.length; m++) {
          form.push({
            schedule_id: seminar_schedule[m].schedule_id,
            sp_academic_year_id: seminar_schedule[m].sp_academic_year_id,
            student_id: this.urlStudentId
          })
        }

        console.log(form, 'submit form')
        if (this.exception_disciplines.includes(discipline.discipline_id) || discipline.no_lecture == 1) {
          if (form.length > 0) {
            let postData = await this.POST_STUDENT_SCHEDULE_CHOOSE(form)
            if (postData) {
              form = []
              this.disciplines = await this.GET_DISCIPLINES(this.urlStudentId)
              this.$message({text: 'Выбор сохранен'})


              for (let i = 0; i < this.disciplines.length; i++) {
                this.disciplines[i].new_lecture = null
                this.disciplines[i].new_seminar = null
                this.disciplines[i].old_lecture = null
                this.disciplines[i].old_seminar = null

                for (let j = 0; j < this.disciplines[i].seminar_lecture_array.length; j++) {
                  for(let k = 0; k < this.disciplines[i].seminar_lecture_array[j].length; k++) {

                    this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count = 0
                    this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count = 0

                    for (let m = 0; m < this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length; m++) {
                      if (this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule[m].choose != null) {
                        this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count++
                      }
                    }

                    for (let n = 0; n < this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length; n++) {
                      if (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule[n].choose != null) {
                        this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count++
                      }
                    }

                    if ((this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count)
                        && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count)
                        && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length !=0)) {
                      this.disciplines[i].old_seminar = this.disciplines[i].seminar_lecture_array[j][k].seminar
                      this.disciplines[i].old_lecture = this.disciplines[i].seminar_lecture_array[j][k].lecture
                    }

                  }
                }
              }



            }
          }
        }
        else {

          if(lecture_schedule.length != 0 && seminar_schedule.length != 0) {

            if (form.length > 0) {
              let postData = await this.POST_STUDENT_SCHEDULE_CHOOSE(form)
              if (postData) {
                form = []

                this.disciplines = await this.GET_DISCIPLINES(this.urlStudentId)
                this.$message({text: 'Выбор сохранен'})


                for (let i = 0; i < this.disciplines.length; i++) {
                  this.disciplines[i].new_lecture = null
                  this.disciplines[i].new_seminar = null
                  this.disciplines[i].old_lecture = null
                  this.disciplines[i].old_seminar = null

                  for (let j = 0; j < this.disciplines[i].seminar_lecture_array.length; j++) {
                    for(let k = 0; k < this.disciplines[i].seminar_lecture_array[j].length; k++) {

                      this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count = 0
                      this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count = 0

                      for (let m = 0; m < this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length; m++) {
                        if (this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule[m].choose != null) {
                          this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count++
                        }
                      }

                      for (let n = 0; n < this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length; n++) {
                        if (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule[n].choose != null) {
                          this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count++
                        }
                      }
                      /*
                      Условие для дисциплин у которых нет лекции (Физическая культура, ИКТ и дисциплины магистратуры) + языковые дисциплины
                      */
                      if (this.exception_disciplines.includes(this.disciplines[i].discipline_id) || this.disciplines[i].no_lecture == 1) {
                        if ((this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length !=0)) {
                          this.disciplines[i].old_seminar = this.disciplines[i].seminar_lecture_array[j][k].seminar
                          this.disciplines[i].old_lecture = this.disciplines[i].seminar_lecture_array[j][k].lecture
                        }
                      }
                      else {
                        if ((this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length != 0) && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length !=0)) {
                          this.disciplines[i].old_seminar = this.disciplines[i].seminar_lecture_array[j][k].seminar
                          this.disciplines[i].old_lecture = this.disciplines[i].seminar_lecture_array[j][k].lecture
                        }
                      }

                    }
                  }
                }



              }
            }

          }

          else {
            this.$error({text: 'Расписание лекции или семинара пусто', })
          }

        }


      }
      else {

        if (discipline.new_seminar || discipline.new_lecture) {


          console.log('update')
          for(let i=0; i < seminar_lecture_array.length; i++) {
            for(let j=0; j < seminar_lecture_array[i].length; j++) {


              if (seminar_lecture_array[i][j].lecture == discipline.old_lecture && seminar_lecture_array[i][j].seminar == discipline.old_seminar) {
                old_lecture_schedule = seminar_lecture_array[i][j].lecture_schedule
                old_seminar_schedule = seminar_lecture_array[i][j].seminar_schedule
              }

              if (seminar_lecture_array[i][j].lecture == discipline.new_lecture && seminar_lecture_array[i][j].seminar == discipline.new_seminar) {
                update_lecture_schedule = seminar_lecture_array[i][j].lecture_schedule
                update_seminar_schedule = seminar_lecture_array[i][j].seminar_schedule
              }

            }
          }

          console.log(old_lecture_schedule, 'old_lecture_schedule')
          console.log(old_seminar_schedule, 'old_seminar_schedule')
          console.log(update_lecture_schedule, 'update_lecture_schedule')
          console.log(update_seminar_schedule, 'update_seminar_schedule')

          let old_schedule = old_lecture_schedule.concat(old_seminar_schedule);
          let update_schedule = update_lecture_schedule.concat(update_seminar_schedule);


          for (let i=0;i<old_schedule.length;i++) {
            updateForm.push({
              id: old_schedule[i].choose.id,
              schedule_id: null,
              sp_academic_year_id: old_schedule[i].sp_academic_year_id,
              student_id: this.urlStudentId,
            })
          }




          if (update_schedule.length == old_schedule.length) {
            for (let i = 0; i < update_schedule.length; i++) {
              updateForm[i].schedule_id = update_schedule[i].schedule_id
            }

            console.log(updateForm, 'updateForm final')


            if (updateForm.length > 0) {
              let putData = await this.PUT_STUDENT_SCHEDULE_CHOOSE(updateForm)
              if (putData) {
                updateForm = []
                update_lecture_schedule = []
                update_seminar_schedule = []
                old_schedule = []
                update_schedule = []

                this.disciplines = await this.GET_DISCIPLINES(this.urlStudentId)
                this.$message({text: 'Выбор обновлен'})
                //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});

                for (let i = 0; i < this.disciplines.length; i++) {
                  this.disciplines[i].new_lecture = null
                  this.disciplines[i].new_seminar = null
                  this.disciplines[i].old_lecture = null
                  this.disciplines[i].old_seminar = null

                  for (let j = 0; j < this.disciplines[i].seminar_lecture_array.length; j++) {
                    for(let k = 0; k < this.disciplines[i].seminar_lecture_array[j].length; k++) {

                      this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count = 0
                      this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count = 0

                      for (let m = 0; m < this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length; m++) {
                        if (this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule[m].choose != null) {
                          this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count++
                        }
                      }

                      for (let n = 0; n < this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length; n++) {
                        if (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule[n].choose != null) {
                          this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count++
                        }
                      }

                      /*
                      Условие для дисциплин у которых нет лекции (Физическая культура, ИКТ и дисциплины магистратуры) + языковые дисциплины
                      */
                      if (this.exception_disciplines.includes(this.disciplines[i].discipline_id) || this.disciplines[i].no_lecture == 1) {
                        if ((this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length !=0)) {
                          this.disciplines[i].old_seminar = this.disciplines[i].seminar_lecture_array[j][k].seminar
                          this.disciplines[i].old_lecture = this.disciplines[i].seminar_lecture_array[j][k].lecture
                        }
                      }
                      else {
                        if ((this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count)
                            && (this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length != 0) && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length !=0)) {
                          this.disciplines[i].old_seminar = this.disciplines[i].seminar_lecture_array[j][k].seminar
                          this.disciplines[i].old_lecture = this.disciplines[i].seminar_lecture_array[j][k].lecture
                        }
                      }

                    }
                  }
                }



              }
            }


          }

          else {
            this.$error({text: 'Количество лекции и семинаров не совпадает', })
          }


        }

        else {
          this.$error({text: 'Выберите другое расписание', })
        }




      }


      this.btnSaveDisabled[discipline_id] = false

    }

  },
  async mounted() {
    //await this.GET_DAYS()
    //await this.GET_DISCIPLINES(this.urlStudentId)
    this.studentInfo = await this.GET_STUDENT_INFO(this.urlStudentId)

    this.disciplines = await this.GET_DISCIPLINES(this.urlStudentId)

    for (let i = 0; i < this.disciplines.length; i++) {
      this.disciplines[i].new_lecture = null
      this.disciplines[i].new_seminar = null
      this.disciplines[i].old_lecture = null
      this.disciplines[i].old_seminar = null

      for (let j = 0; j < this.disciplines[i].seminar_lecture_array.length; j++) {
        for(let k = 0; k < this.disciplines[i].seminar_lecture_array[j].length; k++) {

          this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count = 0
          this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count = 0

          for (let m = 0; m < this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length; m++) {
            if (this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule[m].choose != null) {
              this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count++
            }
          }

          for (let n = 0; n < this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length; n++) {
            if (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule[n].choose != null) {
              this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count++
            }
          }


          /*
          Условие для дисциплин у которых нет лекции (Физическая культура, ИКТ и дисциплины магистратуры) + языковые дисциплины
           */
          if (this.exception_disciplines.includes(this.disciplines[i].discipline_id) || this.disciplines[i].no_lecture == 1) {
            if ((this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count)
                && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count)
                && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length !=0)) {
              this.disciplines[i].old_seminar = this.disciplines[i].seminar_lecture_array[j][k].seminar
              this.disciplines[i].old_lecture = this.disciplines[i].seminar_lecture_array[j][k].lecture
            }
          }
          else {
            if ((this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule_choose_count)
                && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length == this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule_choose_count)
                && (this.disciplines[i].seminar_lecture_array[j][k].lecture_schedule.length != 0) && (this.disciplines[i].seminar_lecture_array[j][k].seminar_schedule.length !=0)) {
              this.disciplines[i].old_seminar = this.disciplines[i].seminar_lecture_array[j][k].seminar
              this.disciplines[i].old_lecture = this.disciplines[i].seminar_lecture_array[j][k].lecture
            }
          }



        }
      }
    }
    console.log(this.disciplines)
    this.loading = false
  },
}

</script>
